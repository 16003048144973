.hamburger-icon {
    width: 30px;
    height: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer; 
}

.hamburger-icon span {
    width: 100%;
    height: 4px;
    background: var(--text-color);
    display: block;
}

.menu-container {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    right: 0;
    top: 20px;
    background: var(--neutral-color);
    border: 1px solid var(--secondary-bg);
    padding: 5px;
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px var(--box-shadow-color);
    max-height: 500px;
    overflow-y: auto;
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}

.dropdown-menu.open {
    transform: translateY(0); 
    opacity: 1; 
    visibility: visible; 
    transition: all 0.3s ease;
}

.menu-item {
    width: 200px; 
    background-color: var(--neutral-color);
    color: var(--secondary-bg);
    padding: 5px 32px; 
    text-align: center;
    text-decoration: none;
    display: block; 
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    word-wrap: break-word;
}

.menu-item:last-child {
    /*border-bottom: none; /* No Border for Last Item */
}

.menu-item:hover {
    background-color: var(--accent-color);
    color: var(--secondary-bg);
}

.menu-divider {
    height: 1px;
    background: var(--menu-divider-color);
    margin: 10px 0; 
}
