.otp-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.otp-input {
    width: calc(100% - 100px); /* Adjust according to the button's width */
    padding: 5px;
    border: 1px solid var(--neutral-color);
    border-radius: 3px;
    margin-right: 10px; /* Space between the input and button */
}

.otp-submit-button {
    padding: 5px 15px;
    border: none;
    background-color: var(--highlight-color2);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.otp-submit-button:disabled {
    background-color: var(--neutral-color);
    cursor: not-allowed;
}
