.notification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--neutral-color);
}

.notification-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.notification-container p {
  line-height: 1.5; 
  word-wrap: break-word; 
  margin-top: 10px;
}

.notification-container p.error {
  color: var(--error-color); 
}

.notification-container button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  color: var(--text-color);
  background-color: var(--highlight-color2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notification-container button:hover {
  background-color: var(--highlight-color1);
}

@media (max-width: 600px) {
  .notification-container h1 {
    font-size: 1.5em;
  }

  .notification-container button {
    padding: 8px 16px;
  }
}
