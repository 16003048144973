.biller-details-container {
    display: flex;
    align-items: center;
  }
  
  .biller-details-table {
    margin-left: 5px; /* Add space between logo and text */
    border-spacing: 5px; /* Add small spacing between cells */
  }
  
  .biller-detail-label,
  .biller-detail-value {
    padding: 0;
    margin: 0;
  }
  
  .biller-detail-label {
    text-align: right; /* Align labels to the right for neat alignment with values */
    padding-right: 10px; /* Add space between label and value */
  }
  
  .biller-detail.error {
    color: var(--error-color);
}