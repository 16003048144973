.dashboard-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 100px;
    box-sizing: border-box;
    width: 100%;
    max-width: var(--max-width-desktop);
    min-height: calc(100vh);
    margin: 0 auto;
}

.biller-cards-container {
    display: grid;
    grid-gap: 20px;
}

.cta-message {
    position: relative;
    margin-top: 25vh;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    background-color: #f7f7f7;
    color: #333;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .biller-cards-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 767px) {
    .biller-cards-container {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}


@media (max-width: 1024px) {
    .dashboard-container {
        max-width: var(--max-width-tablet);
    }
}

@media (max-width: 768px) {
    .dashboard-container {
        max-width: var(--max-width-mobile);
    }
}