.biller {
    display: flex;
    flex-direction: column;
    width: 300px;
    border: 1px solid var(--neutral-color);    
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 0 10px var(--box-shadow-color);
    position: relative;
    min-height: 150px;
}

.biller-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    box-shadow: 0px 0px 5px var(--box-shadow-dark-color);
}

.green {
    background-color: var(--green);
}

.yellow {
    background-color: var(--yellow);
}

.red {
    background-color: var(--red);
}

.grey {
    background-color: var(--gray);
}
