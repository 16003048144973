.site-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #f9f9f9;
}

.powered-by {
    padding: 5px;
}

.footer-content {
    max-height: 100px; 
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    margin: 0 0 10px;
}

.strukd-link,
.footer-content a {
    margin: 0 5px;
    text-decoration: none;
    color: inherit;
}

.strukd-link:hover,
.footer-content a:hover {
    text-decoration: underline;
}

@media (max-width: 480px) {
    .footer-content {
        max-height: 0;
    }

    .footer-content a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80px;
        display: inline-block;
    }

    .site-footer.scrolled-to-bottom .footer-content {
        max-height: 100px; 
    }
}
