/* Common button styles */
.button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

/* Blue button */
button.blue {
  background-color: #007BFF;
}

.button:hover {
  background-color: #0056b3;
}

/* Red button */
button.red {
  background-color: #dc3545;
}

button.red:hover {
  background-color: #c82333;
}

/* Green button */
button.green {
  background-color: #28a745;
}

button.green:hover {
  background-color: #218838;
}

/* On button press */
button:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}