.history-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: all 0.3s ease;
}

.history-modal-content {
    background-color: var(--neutral-color);
    border: 1px solid var(--secondary-bg);
    box-shadow: 0 5px 15px var(--box-shadow-color);
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    position: relative;
    transition: all 0.3s ease;
}

.history-item {
    margin-bottom: 8px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--accent-color);
    padding-bottom: 5px;
}

.history-item-date {
    /* color: var(--text-color); */
}

.history-item-amount {
    font-weight: bold;
}