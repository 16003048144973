.login-container {
  padding: 40px;
  color: var(--secondary-bg);
  background-color: white;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

p {
  font-size: 1.5em;
  margin-bottom: 40px;
  max-width: 600px;
}

.login-container button {
  background-color: var(--highlight-color2);
  color: white;
  padding: 15px 30px;
  font-size: 1.1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: var(--highlight-color1);
}

.login-container .error {
  color: var(--error-color);
  margin-top: 10px;
}