.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-bg);
    color: var(--text-color);
    padding: 10px 20px;
    box-shadow: 0 2px 4px var(--box-shadow-color);
    max-width: var(--max-width-desktop);
    width: 100%;
    min-height: 60px; 
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    width: 50px;
    margin-right: 15px;
}

.user-info {
    display: flex;
    align-items: center;
}

.user-info h2 {
    display: block; 
}

.menu-button {
    position: relative;
    background-color: transparent;
    border: none;
    color: var(--text-color);
    cursor: pointer;
    font-size: 20px;
}

.menu-button:focus {
    outline: none;
}

@media (max-width: 480px) {
    .user-info h2 {
        display: none; 
    }
}

@media (max-width: 1024px) {
    .app-header {
        max-width: var(--max-width-tablet);
    }
}

@media (max-width: 768px) {
    .app-header {
        max-width: var(--max-width-mobile);
    }
}
