.biller-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.biller-footer svg:hover:not(.disabled-icon) {
    cursor: pointer;
}

.disabled-icon {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.biller-footer button {
    padding: 5px 10px;
    border: none;
    background-color: var(--highlight-color2);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.biller-footer button:disabled {
    background-color: var(--neutral-color);
    cursor: not-allowed;
}
