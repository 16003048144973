.add-biller-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--primary-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.add-biller-content {
    position: relative;
    max-width: 375px;
    width: 100%;
    background-color: var(--neutral-color);
    border: 1px solid var(--secondary-bg);
    padding: 20px;
    box-shadow: 0 2px 8px var(--box-shadow-color);
}

.add-biller-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-biller-form select, 
.add-biller-form input,
.add-biller-form button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
}

.add-biller-form label {
    font-weight: bold;
}

.add-biller-form button {
    background-color: var(--highlight-color2);
    color: var(--text-color);
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

.add-biller-form button:hover {
    background-color: var(--highlight-color1);
}
