.close-button {
    position: absolute;
    right: 1px;
    top: 1px;
    background: none;
    font-size: 20px;
    cursor: pointer;
    color: var(---secondary-bg);
    text-decoration: none;
    text-decoration: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  
  .close-button:hover {
    color: var(--secondary-bg);
  }
  