:root {
    --primary-bg: #282c34;
    --accent-color: #61dafb;
    --text-color: #ffffff;
    --secondary-bg: #041635;
    --highlight-color1: #7ac2ff;
    --highlight-color2: #1494ff;
    --neutral-color: #e6e6e6;
    --box-shadow-light-color: rgba(0, 0, 0, 0.1); /* Used for subtle box shadows */
    --box-shadow-color: rgba(0, 0, 0, 0.2); /* Used for box shadows */
    --box-shadow-dark-color: rgba(0, 0, 0, 0.3); /* Darker box shadow */
    --border-light-grey: #eee; /* Used for borders */
    --error-color: #d9534f; /* Used for error messages */
    --menu-divider-color: #ccc; /* Menu divider color */
    --green: green; /* Status green color */
    --yellow: yellow; /* Status yellow color */
    --yellow: orange; /* Status yellow color */
    --red: red; /* Status red color */
    --grey: grey; /* Status grey color */

    --max-width-desktop: 1200px;
    --max-width-tablet: 768px;
    --max-width-mobile: 375px;
  }